@import '@percent/admin-dashboard/identity.scss';

.container {
  margin-top: 24px;
  border-radius: 8px;
  padding: 28px 24px 34px !important;
}

.title {
  border-bottom: 1px solid $gray-040;
  padding-bottom: 19px;
}

.text {
  font-size: 16px;
  font-weight: normal;
  color: $secondary;
  margin: 0;
  padding: 0;
}

.subtext {
  font-size: 14px;
  font-weight: normal;
  color: $gray-160;
  padding: 0;
  margin: 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.optionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 32px 0;
  border-bottom: 1px solid $gray-040;
}

.buttonContainer {
  width: 200px;
}
