@import '@percent/admin-dashboard/identity';

@mixin item-box {
  border-left: 2px $primary solid;
  display: flex;
  align-items: center;
  padding-left: 30px;
  height: 36px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
}

@mixin item-box--default {
  border-left: 2px $gray-010 solid;
}

.svgActive {
  fill: $primary;
}

.svgDefault {
  fill: $gray-160;
}

.active {
  color: $primary;
  background: linear-gradient(90deg, rgba(233, 85, 78, 0.04) 0%, rgba(233, 85, 78, 0) 100%);
  height: 40px;
  padding: 2px 16px 2px 0px;

  svg {
    path {
      fill: $primary;
    }
  }

  .box {
    @include item-box;
  }
}

.default {
  color: $gray-140;
  height: 40px;
  padding: 2px 16px 2px 0px;

  svg {
    path {
      fill: $gray-140;
    }
  }

  .box {
    @include item-box;
    @include item-box--default;
  }
}

.withLogo {
  height: 56px;
  padding: 10px 16px 10px 0px;
}

.totalNumber {
  background: #fff0f1;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-size: 13px;
}
