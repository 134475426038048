@import '@percent/admin-dashboard/identity.scss';

@mixin text {
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 72px;
  left: 242px;
  width: calc(100% - 282px);
  border-radius: 0px !important;
  box-shadow: none;
  padding: 0 0 24px 40px;
}

.divider {
  height: 1px;
  width: calc(100% - 40px);
  margin-bottom: 24px;
}

.topBarDivider {
  margin: 0 16px;
}

.text {
  @include text;

  font-size: 16px;
}

.organisationInfoWrapper {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  display: flex;
  align-items: flex-end;

  button {
    width: 185px;
    height: 40px;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}

.generalInfoWrapper {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 16px 24px !important;
  margin-top: 100px;
}

@mixin topBarTextMixin {
  @include text;

  font-size: 14px;  
}

.topBarText {
  @include topBarTextMixin;

  margin-right: 16px;
}

.topBarSubtext {
  @include topBarTextMixin;

  overflow: visible;
  color: $gray-160;
  margin-left: 5px;
}
