@import '@percent/admin-dashboard/identity.scss';

@mixin status {
  display: flex;
  align-items: center;
  font-family: 'MarkProBold', sans-serif;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;

  svg {
    margin-right: 8px;
  }
}

.clear {
  color: $success;

  @include status;
}

.pending {
  color: $gray-140;

  @include status;
}

.flag {
  color: $alert;

  @include status;
}

.na {
  color: #a0adc3;

  @include status;
}

.invalid {
  color: $gray-140;

  @include status;
}
