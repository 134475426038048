@import '@percent/admin-dashboard/identity';

@mixin text {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.notFoundDataAlert {
  width: 100%;
}

.listContainer {
  margin-top: 24px;
  border-radius: 8px;
  padding: 28px 24px 34px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 28px;
  font-weight: bold;
}

.bankDetailsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0px;
  padding-left: 0;
  font-weight: normal;
  border-top: 1px solid $gray-040;

  li {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gray-040;
    min-height: 48px;
    position: relative;

    p {
      margin: 16px 0 16px;
      min-width: 200px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $secondary;
    }

    span {
      @include text;
      display: flex;
      align-items: center;
      margin: 16px 0 16px;
      color: $gray-160;
    }

    img {
      margin-right: 7px;
    }
  }
}