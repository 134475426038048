.fieldsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    width: 49.5%;
  }
}

.buttonsWrapper {
  margin-top: 90px; 
  margin-bottom: 16px;
  text-align: right;

  button {
    margin-left: 8px;
  }
}