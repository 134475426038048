@import '@percent/admin-dashboard/identity.scss';

.row {
  cursor: pointer;
}

.arrow {
  transform: rotate(-90deg);

  path {
    fill: $secondary;
  }
}

.email {
  max-width: 300px;
}

.filtersContainer {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.filtersWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.filtersAndChipsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filtersChipContainer {
  display: flex;
  gap: 8px;
  margin: 8px 0;
  flex-wrap: wrap;
}
