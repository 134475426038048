@import '@percent/admin-dashboard/identity.scss';

.tableWrapper {
  table thead th {
    font-family: 'MarkProMedium', sans-serif;
  }
}

.tableRow {
  border: {
    top: 1px solid $gray-020;
    bottom: 1px solid $gray-020;
  }
}

.menuBtn {
  padding: 10px;
  cursor: pointer;
}

.filtersAndActionsWrapper {
  width: 100%;
  align-items: center;
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
}

.searchWrapper {
  flex-grow: 1;
  min-width: 600px;
}

.donorWrapper {
  display: flex;
  flex-direction: column;

  .email {
    font-size: x-small;
  }
}
