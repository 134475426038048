@import '@percent/admin-dashboard/identity.scss';

.topBar {
  height: 72px;
}

.toolBar {
  width: calc(100% - 266px);
}

.toolBar {
  display: block;
  width: calc(100% - 266px);
}

.breadcrumbs {
  flex: 1;
  min-width: 0;

  ol {
    flex-wrap: nowrap;
    margin-left: 40px;

    li:last-child {
      flex: 1;
      min-width: 0;
    }
  }
}

.breadcrumb {
  color: $gray-140;
  white-space: nowrap;
}

.breadcrumbActive {
  display: flex;
  align-items: center;
  span {
    color: $secondary;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.userDetail {
  display: block;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;

  h6:first-child {
    font-weight: 600;
    color: black;
  }
}

.nameText {
  color: $secondary;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  white-space: nowrap;
}

.roleText {
  color: $gray-120;
}

.userProfileMenu {
  a {
    color: $secondary;
    &:active {
      color: $secondary;
    }
  }
}

.userProfileMenuItem {
  width: 224px !important;

  svg {
    margin-right: 12px;
  }
}

.dropdown {
  transform: rotate(180deg);
}

.menuItem {
  display: flex;
  justify-content: space-between;
  border-left: 1px solid $gray-040;
  padding-left: 0;
  width: 224px;
}

.ellipsisParent {
  display: flex;
  align-items: center;
  height: 100%;
}

.ellipsisText {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  margin-right: 32px;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ellipsisContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  min-width: 0;
}

.ellipsisBox {
  white-space: nowrap;
}

.navContainer {
  height: 100%;
}
