@import '@percent/admin-dashboard/identity.scss';

@mixin text {
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 72px;
  left: 242px;
  width: calc(100% - 282px);
  border-radius: 0px !important;
  box-shadow: none;
  padding: 0 0 24px 40px;
}

.divider {
  height: 1px;
  width: calc(100% - 40px);
  margin-bottom: 16px;
}

.text {
  @include text;
  font-size: 16px;
}

.organisationInfoWrapper {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  display: flex;
  align-items: center;

  button {
    width: 152px;
    height: 40px;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}
