@import '@percent/admin-dashboard/identity.scss';

.listContainer {
  margin-top: 24px;
  border-radius: 8px;
  padding: 28px 24px 34px !important;
}

.title {
  padding-bottom: 44px;
}

.detailsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  padding-left: 0;

  li {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gray-040;
    min-height: 48px;
    position: relative;
    align-items: center;

    p {
      margin: 16px 0 16px;
      min-width: 224px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $secondary;
    }

    span,
    a {
      margin: 16px 0 16px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $gray-160;
      word-wrap: break-word;
    }

    a {
      text-decoration: underline;
      text-underline-position: under;
    }

    button {
      align-self: center;
    }
  }
}

.countryDefinition {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 8px;
    border-radius: 4px;
  }

  a {
    display: flex;
    align-items: center;
    height: 0px;
  }
}

.invalidRegistry {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 2px 4px;
  background-color: $alert-100;
}

.topMargin {
  margin-top: 24px;
}
