@import '@percent/admin-dashboard/identity.scss';

.tagContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fafbfc;
  cursor: pointer;
  border-bottom: 1px solid $gray-020;
  min-height: 48px;
  padding: 0 35px 0 24px;
}

.hidden {
  display: none;
}

.subTagContainer {
  background: white;
}

.tagName {
  margin-top: 8px;
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: $secondary;
}

.subTagsCount {
  margin-top: 8px;
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: $gray-120;
}

.arrow {
  margin: 12px 0 0 auto;
  transform: rotate(180deg);

  path {
    fill: $secondary;
  }
}

.arrowDown {
  transform: rotate(0deg);
}
