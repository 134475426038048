@import '@percent/admin-dashboard/identity.scss';

.tagsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;

  li {
    display: flex;
    flex-direction: column;
  }
}

.settingsContainer {
  display: flex;
  flex-direction: column;
}

.saveButton {
  width: 150px;
  height: 48px;
  margin: 16px 30px 0 0;
  align-self: flex-end;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
}

.headerText {
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: $gray-160;
}

.partnerName {
  font-family: 'MarkProBook', sans-serif;
}
