@import '@percent/admin-dashboard/identity';

.viewDocument {
  text-decoration: underline;
  text-underline-offset: 0.13rem;
  cursor: pointer;
  color: var(--colors-neutral-300);
  font-weight: 400;
}

.editButton {
  position: relative !important;
  top: -5px !important;
}

.spacerWithLine {
  border-bottom: 1px solid $gray-040;
  width: 100%;
  margin-top: 15px;
}

.markOrgAsUnableToPay {
  display: flex;
}
