@import '@percent/admin-dashboard/identity.scss';

.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: $white !important;
  display: flex;
  justify-content: center;
  padding-top: 56px;
}

.container {
  display: flex;
  width: 368px;
  flex-direction: column;
  align-items: center;
}

.header {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 16px;
  color: $secondary;
}

.subtext {
  font-size: 12px;
  color: $gray-160 !important;
  margin-bottom: 18px;
  text-align: center;
}

.button {
  background-color: $gray-020 !important;
  color: $gray-140 !important;
  font-weight: 700;
  margin-right: 8px;
}

.doneButton {
  padding: 25px;
}

.copyWrapper {
  display: flex;
  align-items: center;
  margin-right: 32px;
  gap: 5px;
}

.downloadWrapper {
  display: flex;
  align-items: center;
}

.action {
  margin-right: 8px;
}

.actionText {
  font-size: 13px;
  color: $gray-140 !important;
}

.actionWrapper {
  display: flex;
  margin-top: 28px;
  margin-bottom: 16px;
}

.separator {
  width: 368px;
  height: 1px;
  background-color: $gray-040 !important;
  margin-bottom: 40px;
}

.download {
  fill: $gray-140;
  width: 18px;
  height: 18px;
  border: 1px solid $gray-060;
  border-radius: 8px;
  padding: 6px;
  margin-right: 8px;
}

.download:hover {
  cursor: pointer;
  fill: $gray-160;
}
