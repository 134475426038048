@import '@percent/admin-dashboard/identity.scss';

.strengthBarContainer {
  display: flex;
  flex-direction: row;
}

.strengthBar {
  display: flex;
  flex-grow: 5;
  height: 4px;
  margin-right: 4px;
  background-color: $gray-020;
}

.veryWeak {
  background-color: $alert;
}

.weak {
  background-color: $alert;
}

.medium {
  background-color: $warning-alt;
}

.strong {
  background-color: $success;
}

.veryStrong {
  background-color: $success;
}

.strengthText {
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  p:first-child {
    font-family: 'MarkProMedium', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $secondary;
    margin: 1px 4px 0 0;
  }

  p:nth-child(2) {
    font-family: 'MarkPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $secondary;
    margin: 0;
  }
}
