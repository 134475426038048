@import '@percent/admin-dashboard/identity.scss';

.row {
  cursor: pointer;

  &:hover {
    background-color: $gray-010 !important;
  }
}

.link {
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  color: $secondary;
  text-decoration: underline;

  &:visited,
  :active {
    color: $secondary;
  }
}

.rowIconForward {
  padding: 16px 0;
  width: 16px;
}

.arrow {
  transform: rotate(-90deg);

  path {
    fill: $secondary;
  }
}

.tableCellEmail {
  max-width: 320px;
}

.validationRequestlink a {
  color: $secondary;
  text-decoration: underline;
  text-underline-position: under;
}
