@import '@percent/admin-dashboard/identity.scss';

#btnCloseDialog {
  cursor: pointer;
  float: right;
  margin: 20px 0;
  color: transparent;
  background: none;
  border: none;
  text-align: right;
  position: absolute;
  right: 0;
}

#dialogTitle {
  padding: 16px 0;
  display: inline-block;
  width: 85%;
  color: var(--colors-black);
}

#dialogHeader {
  margin: 0 24px;
}

.dialog {
  border-radius: 8px;
}

.dialogMargin {
  margin: 0 24px 10px;
}

.headerTitle {
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
}