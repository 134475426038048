@import '@percent/admin-dashboard/identity.scss';

.disableHorizontalResizing {
  resize: vertical;
}

.disableHorizontalAndVerticalResizing {
  resize: none;
}

.disableVerticalResizing {
  resize: horizontal;
}

.field {
  color: $secondary;

  label {
    margin-bottom: 5px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
  }

  textArea {
    padding: 10px;
    border: 1px solid $gray-040;
    box-sizing: border-box;
    border-radius: 8px;
    display: block;
    width: 100%;
    background: $white;
    color: $secondary;
    font: inherit;
    font-size: 12px;
    &:disabled {
      background: $gray-010;
      color: $gray-200;
    }
  }
}

textarea::-webkit-input-placeholder {
  font-size: 12px;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
}

textarea:-ms-input-placeholder {
  font-size: 12px;
}

textarea::placeholder {
  font-size: 12px;
}

textarea:read-only {
  background: $gray-020;
  border: none;
}

textarea:focus {
  border: 1px solid $secondary;
}
