@import '@percent/admin-dashboard/identity.scss';

.websiteLink {
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: 450 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.25px !important;
  text-decoration-line: underline;
  color: $info-400 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container {
  margin-top: 16px;
}
