@import '@percent/admin-dashboard/identity.scss';

.buttonsWrapper {
  margin-bottom: 16px;
  text-align: right;

  button {
    margin-left: 8px;
  }
}

.info {
  display: flex;
  flex-flow: row;
  margin-bottom: 36px;

  svg {
    margin-right: 4px;
  }
}

.label {
  margin-bottom: 5px;
  font-size: 12px;
}

.formFieldWrapper {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 12px;
  }
}

.datePickerWrapper {
  position: relative;
  
  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}

.datePicker{
  width: 100%;
  border: 1px solid $gray-040 !important;
  border-radius: 8px;

  div::before,
  div::after {
    content: none;
  }
  
  input {
    padding: 14px 32px 14px 14px;
    font-size: 14px;
    line-height: 19px;
    background-color: $gray-000;
    border-radius: 8px;
    cursor: pointer;

    &:focus {
      border: none
    }
  }
}