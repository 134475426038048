.button {
  margin-top: 32px;
  width: 145px;
  height: 48px;
}

.buttonWrapper {
  display: flex;
  align-items: center;

  button {
    width: 152px;
    height: 40px;
  }
}

.editorContainer {
  margin-top: 10px;
}