@import '@percent/admin-dashboard/identity.scss';

.nonprofitDetail {
  padding: 28px 24px 34px !important;
}

.heading {
  margin-bottom: 8px;
}

.additionalInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerList {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  gap: 10px;
  margin: 1rem 0 0;

  li {
    display: flex;
    align-items: center;
  }
}

.buttonsContainer {
  white-space: nowrap;
  button {
    width: 140px;
    height: 40px;
  }

  :nth-of-type(2) {
    margin-left: 8px;
  }
}

.rejectButton {
  background-color: $alert-light;
  color: $alert;

  &:hover {
    background-color: $alert;
    color: $white;
  }
}

.approveButton {
  background-color: $successLight;
  color: $success;
  margin-left: 8px;

  &:hover {
    background-color: $success;
    color: $white;
  }
}

.ellipsisParent {
  display: flex;
  flex-direction: column;
}

h6 {
  font-size: 1rem;
}

.ellipsisText {
  flex: 1;
  min-width: 0;
  margin-right: 32px;
  h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ellipsisContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  min-width: 0;
}

.headingContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.claimedBadge {
  margin-bottom: 0px;
}

.validationStatus {
  width: 100%;
  padding-top: 25px;
}

.bankDetailsAnchor {
  cursor: pointer;
  color: $primary;
}