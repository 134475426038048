@import '@percent/admin-dashboard/identity.scss';

.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 26px;
  border-bottom: 1px solid $gray-030 !important;
}

.breadcrumbItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.separator {
  width: 32px;
  height: 1px;
  margin: 0 16px;
  background-color: $gray-030 !important;
}

.activeStepText {
  font-weight: 400;
  font-size: 12px;
  color: $gray-160 !important;
  margin: 0;
}

.activeStepNumber {
  width: 20px;
  height: 20px;
  border: 1px solid $blue !important;
  border-radius: 50%;
  font-weight: 700;
  font-size: 12px;
  color: $blue !important;
  text-align: center;
  line-height: 18px;
  margin: 0 11px 0 0;
}

.stepText {
  font-weight: 400;
  font-size: 12px;
  color: $gray-100 !important;
  margin: 0;
}

.stepNumber {
  font-weight: 700;
  font-size: 12px;
  color: $gray-100 !important;
  margin: 0 11px 0 0;
}

.doneStep {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $success !important;
}
