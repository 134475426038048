@import '@percent/admin-dashboard/identity.scss';

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@mixin thumbnailOutline {
  position: absolute;
  top: 0px;
  left: 0px;
  content: '';
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  border: 4px solid $primary;
  pointer-events: none;
  border-radius: 8px;
}

@mixin agentVerificationTypeThumbnailOutline {
  height: calc(100% - 3px);
  width: calc(100% - 3px);
  border: 2px solid $success-400;
}

@mixin agentVerificationTypeThumbnailDot {
  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: $success-400;
    top: -5px;
    left: 6px;
    border-radius: 50%;
    animation: scaleIn 300ms ease-in-out;
    transition: width 200ms ease-in-out, height 200ms ease-in-out;
  }
}

@mixin agentVerificationTypeThumbnailPressed {
  &::after{
    border-width: 3px;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
  }
  &:before {
    width: 16px;
    height: 16px;
  }
}

@mixin hideThumbnailOutline {
  &:hover:after {
    display: none;
  }
}

.gallery {
  display: flex;
  width: 100%;

  button {
    width: 160px;
    height: 160px;
    border: none;

    span {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
    }
  }

  img {
    vertical-align: middle;
    line-height: 0;
    border-radius: 8px;
    width: 160px;
    height: 160px;
    object-fit: cover;
  }
}

.galleryWithPreview {
  img {
    border-radius: 8px;
    width: 64px;
    height: 64px;
    object-fit: cover;
  }

  button {
    width: 64px;
    height: 64px;
    border: none;
    margin-right: 8px !important;
    margin-bottom: 8px !important;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 32px;
      }

      &[data-type='agent_verification'] {
        &:after {
          @include thumbnailOutline;
          @include agentVerificationTypeThumbnailOutline;
          background: none;
        }
        @include agentVerificationTypeThumbnailDot;
      }
    }

    @include hideThumbnailOutline;

    &[aria-pressed='true'] {
      span {
        &:not([data-type='agent_verification'] ) {
          &:after {
            @include thumbnailOutline;
            background: none;
          }
        }
       

        &[data-type='agent_verification'] {
          @include agentVerificationTypeThumbnailPressed;
        }
      }
    }
  }
}

.galleryFullscreen {
  display: block;

  > div:first-child {
    background: white;
  }

  img,
  iframe {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    border-radius: 0px;
  }

  iframe {
    display: block; /* iframes are inline by default */
    background: #000;
    border: none; /* Reset default border */
    width: 100%;
    height: 100vh;
  }

  .thumbnail {
    width: 92px;
    height: 92px;
    vertical-align: middle;
    border-radius: 0px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      @include hideThumbnailOutline;
    }

    @include hideThumbnailOutline;
  }
}

.thumbnail {
  position: relative;
  display: inline-block;
  transition: border 0.3s ease-out;
  background: $gray-020;
  padding: 0;
  outline: none;
  border: none;
  border-radius: 8px;

  span {
    display: block;
    height: 100%;

    &:hover:after {
      @include thumbnailOutline;
    }

    &[data-type='agent_verification'] {
      &:hover:after {
        @include agentVerificationTypeThumbnailOutline;
      }
    }
  }

  &:hover:after {
    position: absolute;
    width: 10px;
    height: 10px;
    right: 24px;
    bottom: 24px;
    content: url('../../assets/images/hover-box.svg');
    cursor: pointer;
    background: transparent;
    pointer-events: none;
  }
}

.documentPreview {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 8px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  > iframe {
    width: 100%;
    height: 100%;
    min-height: 550px;
  }
}
