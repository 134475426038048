@import '@percent/admin-dashboard/identity.scss';
@mixin statusContainer {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  margin-top: 10px;
  height: 28px;
  background: $successLight;
  border-radius: 4px;
}

@mixin statusText {
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
}

.statusBoxActive {
  @include statusContainer;
  background: $successLight;
  .successColor {
    @include statusText;
    color: $success;
  }
}

.statusBoxAlert {
  @include statusContainer;
  background: $alert-light;

  .alertColor {
    @include statusText;
    color: $alert;
  }
}

.statusBoxWarning {
  @include statusContainer;
  background: $warning-alt-light;

  .warningColor {
    @include statusText;
    color: $warning-alt;
  }
}
