@import '@percent/admin-dashboard/identity.scss';

.field {
  width: 100%;
  text-align: left;
  color: $secondary;

  label {
    margin-bottom: 5px;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: $secondary;
  }

  div {
    margin-top: 0px;
    border-bottom: none;
    display: block;

    &::before,
    &::after {
      border-bottom: none;
    }

    &:focus {
      outline: none;
    }
  }

  input {
    outline: none;
    display: block;
    width: 100%;
    height: 48px;
    padding: 0.375rem 0.75rem;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: $secondary;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $gray-060;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:disabled {
      background: $gray-010;
      border: 1px solid $gray-060;
      box-sizing: border-box;
      border-radius: 8px;
      color: $gray-200;
    }
  }
}

.fieldInput {
  border: 1px solid $primary !important;
}

input::-webkit-input-placeholder {
  color: $gray-160;
  font-size: 12px;
}

input::-moz-placeholder {
  color: $gray-160;
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:read-only:not([type='radio']) {
  background: $gray-020;
  border: none;
}

input:focus:not([type='checkbox']) {
  border: 1px solid $secondary;
}

.addonLabelClassname {
  float: right;
  text-decoration: underline;
  color: $gray-160;
  font-size: 12px;
  text-underline-position: under;
}
