@import '@percent/admin-dashboard/identity.scss';

.relationships {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.relationshipWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border: 1px solid var(--colors-gray-90);
  border-radius: 8px;
  color: $gray-190;
}

.relationshipDetails {
  display: flex;
  align-items: center;
  gap: 8px;
}
