@import '@percent/admin-dashboard/identity.scss';

.subTag {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-040;
  padding-block: 12px;

  &:last-child {
    border: 0;
  }
}

.box {
  width: 100%;
  max-width: 304px;
  margin-right: 50px;
}

.container {
  background: $gray-010;
  border-radius: 999px;
  width: fit-content;
  padding: 5px 16px;
  cursor: pointer;

  span {
    padding: 0 !important;
    margin-top: 0 !important;
  }

  svg {
    margin: 0 !important;
  }
}

.active {
  background: #f0efff;

  svg {
    fill: #6e62ff;
  }
}

.subTagTitle {
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: $gray-190;
  margin-left: 5px;
}

.disabled {
  .subTagTitle {
    color: #6b7588;
  }
}

.description {
  width: fit-content;
  font-family: 'MarkProBook', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: $gray-160;
}

.sensitive {
  background: $alert-100;

  svg {
    fill: $alert-400;
  }
}

.alert {
  padding-left: 12px !important;
}

.highlight {
  background-color: transparent;
  color: $info-400;
}

.highlighted {
  color: $info-400;
  background: transparent;
}
