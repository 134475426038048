@import '@percent/admin-dashboard/identity.scss';

.eligibilityContainer {
  padding: 24px;
  width: 100%;
  min-width: 400px;
  max-width: 800px;
  flex: 1;
}

.detailsHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-040;
  padding-bottom: 15px;

  h6 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: $secondary;
  }
}

.tagsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0px;
  padding-left: 0;

  li {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $gray-020;
    min-height: 48px;
  }
}

.tagTitle {
  display: flex;
  flex-direction: row;
  padding: 16px 0;

  p {
    margin: 0;
    font-family: 'MarkProMedium', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: $secondary;
  }

  svg {
    height: 19px;
  }
}

.subTagsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 12px;

  & > div {
    margin: 8px 8px 0 0;
  }
}

.subTag {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray-010;
  border-radius: 4px;
  padding: 2px 12px;
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  color: $gray-140;
}

.emptyBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
