@import '@percent/admin-dashboard/identity.scss';

.container {
  margin-top: 16px;
}

.emailFieldWrapper {
  display: flex;
  justify-content: space-between;

  > button {
    white-space: nowrap;
  }
}

.noEmail {
  display: flex;
  align-items: center;
  align-self: center;
  height: fit-content;
  padding: 4px;
  border-radius: 4px;
  background: $alert-light;
  font-family: 'MarkProMedium', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: $alert;

  svg {
    width: 10px;
    height: 11px;
    padding: 0 7px 0 3px;

    path {
      fill: $alert;
    }
  }
}

.greenBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 4px;
  margin-top: 4px;
  font-family: 'MarkProMedium', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #55ba7d;
  background: rgba(85, 186, 125, 0.1);
  border-radius: 4px;
  max-height: 24px;
  max-width: fit-content;
  svg {
    width: 11px;
    margin: 0 5px 0 2px;
  }
}

.organisationFieldWrapper {
  display: flex;
}

.websiteActionsWrapper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 12px;
  margin-left: auto;
  gap: 16px;
  justify-content: flex-end;
}

.approveButtonWrapper {
  display: inherit;
  button {
    white-space: nowrap;
  }
  :not(:disabled) {
    > span {
      color: #008050 !important;
    }
  }
}
.websiteLink {
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: 450 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.25px !important;
  text-decoration-line: underline;
  color: $info-400 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noWebsite {
  display: flex;
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $gray-140;
}

.countryFieldWrapper {
  display: flex;
  gap: 8px;
}
