@import '@percent/admin-dashboard/identity.scss';

.nonprofitDetail {
  padding: 28px 24px 34px !important;
}

.heading {
  margin-bottom: 8px;
}

.buttonsContainer {
  white-space: nowrap;
  button {
    width: 140px;
    height: 40px;
  }
}

.ellipsisParent {
  display: flex;
  align-items: center;
}

h6 {
  font-size: 1rem;
}

.ellipsisText {
  flex: 1;
  min-width: 0;
  margin-right: 32px;
  h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ellipsisContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  min-width: 0;
}

.badgesContainer {
  span {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.alertContainer {
  margin-bottom: 8px;
}
