@import '@percent/admin-dashboard/identity.scss';

.autoCompleteFieldSet {
  font-size: 14px;
  font-weight: normal;

  div:first-of-type {
    margin-top: 0px;
  }

  fieldset {
    height: 48px;
    border-color: $gray-160 !important;
  }

  button {
    margin-top: -2.5px !important;
  }

  svg {
    fill: $gray-160;
  }

  input {
    border-color: transparent;
    margin-top: -5px;
  }

  label {
    font-size: 14px;
    color: $secondary !important;
  }
}

.renderGroup {
  background-color: $gray-020;
  color: $gray-160;
}

.options {
  font-size: 14px;
}

.definition {
  color: $gray-120;
  font-size: 14px;
  font-weight: 400;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
}
