@import '@percent/admin-dashboard/identity.scss';
@import '../../../../../common/components/timeLeft/TimeLeft.module.scss';

.detailsContainer {
  padding: 24px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 24px;
}

.detailsHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-040;
  padding-bottom: 15px;

  h6 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: $secondary;
  }
}

.detailsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 8px 0;
  padding-left: 0;

  li {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gray-040;
    min-height: 48px;

    p {
      margin: 16px 0 16px;
      min-width: 100px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $secondary;
    }

    span,
    a {
      font-family: 'MarkProBook', sans-serif;
      margin: 16px 0 16px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $gray-160;
      overflow: hidden;
      word-wrap: break-word;
    }

    button {
      align-self: center;

      span {
        margin: 0;
        color: $primary !important;
      }
    }
    button:disabled {
      background-color: $gray-020;
      span {
        color: $gray-140 !important;
      }
    }
  }
}

.imagesContainer {
  margin-top: 16px;
}

.nonprofitImage {
  width: 270px;
  margin-right: 16px;
  margin-bottom: 16px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
}

.modalBody {
  margin: 0 24px 0px;
}

.dialogFooter {
  padding: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.labelName {
  color: var(--colors-black);
  font-weight: 500;
  font-size: 14px;
}

.value,
.viewDocument {
  color: var(--colors-gray-700);
  font-weight: 400;
  font-size: 14px;
}

.viewDocument,
.viewBankDetails {
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}

.findBankDetails {
  color: var(--colors-gray-900);
  span {
    font-weight: 500;
    color: var(--colors-black);
  }
}
