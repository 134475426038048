.flexContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.gridContainer {
  border: 1px solid #F1F0F2;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;

  :global(.mantine-Grid-root) {
    box-sizing: border-box;
  }

  :global(.mantine-Grid-col) {
    box-sizing: border-box;
  }
}

.gridColumn {
  border-bottom: 1px solid #F1F0F2;
  padding: 0;
  box-sizing: border-box;
}

.gridInner {
  padding: 8px;
  box-sizing: border-box;

  :global(.mantine-Grid-root) {
    box-sizing: border-box;
  }

  :global(.mantine-Grid-col) {
    box-sizing: border-box;
  }
}

.lastGridColumn {
  padding: 0;
  box-sizing: border-box;

  .exceptionGridColumn {
    border-bottom: 1px solid #F1F0F2;
    box-sizing: border-box;
  }

  :global(.mantine-Grid-root) {
    box-sizing: border-box;
  }

  :global(.mantine-Grid-col) {
    box-sizing: border-box;
  }
} 

