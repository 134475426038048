@import '@percent/admin-dashboard/identity.scss';

@mixin text {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.dialogBody {
  padding: 0 24px;
}

.dialogFooter {
  padding: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.buttonWrapper {
  button:nth-child(2) {
    margin-left: 16px;
  }

  button {
    width: 152px;
    height: 40px;
  }

  .rejectButton {
    background-color: $alert-light;
    color: $alert;

    &:hover {
      background-color: $alert;
      color: $white;
    }
  }
}

.detailsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  padding-left: 0;

  li {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gray-040;
    min-height: 48px;

    p {
      margin: 16px 0 16px;
      min-width: 114px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $secondary;
    }

    span,
    a {
      @include text;

      margin: 16px 0 16px;
      color: $gray-160;
    }

    button {
      align-self: center;

      span {
        margin: 0;
        color: $primary !important;
        line-height: 17px;
      }
    }
    button:disabled {
      background-color: $gray-020;
      span {
        color: $gray-140 !important;
        padding: 5px;
      }
    }
  }
}
