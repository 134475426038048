@import '@percent/admin-dashboard/identity.scss';

.wrapper {
  padding: 24px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid $gray-020;
  padding-bottom: 19px;

  h6 {
    margin-right: 16px;
  }
}

.detailsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 22px;
  padding-left: 0;
  
  li {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gray-020;
    min-height: 48px;
    position: relative;
  
    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 16px 0 16px;
      min-width: 224px;
      font-weight: normal;

      &:first-of-type {
        color: $secondary;
      }
    }
     
    button {
      align-self: center;
    }
  }
}