.actionDialogContent {
    padding: 48px 24px !important;
    p {
      margin: 16px 0 32px;
    }
}

.svg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.singleButtonWrapper {
    display: flex;
    margin-top: 16px;
    justify-content: center;
  }