@import '@percent/admin-dashboard/identity.scss';

.tableHeaderButton {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
}

.tableHeader {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}

.tableRow {
  border: {
    top: 1px solid $gray-020;
    bottom: 1px solid $gray-020;
  }
  cursor: pointer;
}