@import '@percent/admin-dashboard/identity.scss';

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 2px 12px;
  background-color: $gray-010;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  color: $gray-140;
}

.status--approved {
  background-color: $successLight;
  color: $success;
}

.status--rejected {
  background-color: $primaryLight;
  color: $primary;
}
