@import '@percent/admin-dashboard/identity.scss';

.container {
  border-radius: 8px;
  padding: 28px 24px 24px !important;
}

.title {
  display: flex;
  flex-direction: row;
  padding-bottom: 22px;
  justify-content: space-between;
}

.subTitle {
  margin: 22px 0 16px;
  font-family: MarkPro, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: $gray-160;
}

.previewButton {
  border: none;
  margin-top: -6px;
  padding-inline: 16px;

  span {
    color: $primary;
    text-decoration: underline;
    font-family: MarkPro, Arial, Helvetica, sans-serif;
  }
}
