@import '@percent/admin-dashboard/identity.scss';

.partnerDetailContainer {
  border-radius: 8px;
}

.partnerButtons {
  border-bottom: 1px solid $gray-020;
  margin-right: 30px;
  margin-top: 20px;
}

.partnerDetail {
  padding-left: 24px !important;
  padding-top: 28px !important;
}

.partnerName {
  border-bottom: 1px solid $gray-020;
  margin-right: 30px;
  padding-bottom: 20px;
}

.memberTableGrid {
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 0px;
  padding-top: 10px;
}

.tabs {
  display: flex;
  height: 64px;

  &.MuiTabs-scroller {
    border: 1px solid;
  }

  button {
    min-width: auto;
    padding: 0 0;
    margin: 6px 32px 6px 0;
  }
}

.tabs.MuiTabs-scroller {
  border: 1px solid;
}

.indicator {
  background-color: $gray-020;
  margin-top: -1px;
  width: 100%;
  height: 1px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}

.eligibilitySettings {
  margin-left: -24px;
}

.sso {
  margin-left: -24px;
}
