@import '@percent/admin-dashboard/identity.scss';

$placeholder-color: #6b7588;

.fieldLabel {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.field {
  text-align: left;
  width: 100%;

  label {
    margin-bottom: 5px;
    display: inline-block;
    font-size: 12px;
    color: $secondary;
  }

  input {
    outline: none;
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: $secondary;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #ffffff;
    border: 1px solid #dadfe8;
    box-sizing: border-box;
    border-radius: 8px;
    height: 48px;
    padding: 12px 10px;

    &:valid {
      color: $secondary;
    }
    &:invalid {
      color: $primary;
    }
  }
}

.currency {
  display: inline-block;
  position: relative;
  input {
    padding-left: 10px;
  }
  &:after {
    position: absolute;
    content: attr(data-after-content);
    right: 20px;
    color: $gray-140;
    top: 12px;
    font-weight: 500;
    font-size: 16px;
  }
}

.addOnDefault {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::placeholder {
  font-size: 12px;
  letter-spacing: 0.25px;
  color: $placeholder-color;
  font-family: 'MarkPro', sans-serif;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $placeholder-color;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $placeholder-color;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $placeholder-color;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $placeholder-color;
}
