@import '@percent/admin-dashboard/identity.scss';

@mixin default {
  border-radius: 8px;
  height: 32px;
  padding-left: 24px;
  padding-right: 24px;
}

.alignLeft {
  float: left;
  @include default;
}

.alignCenter {
  float: none;
  @include default;
}

.alignRight {
  float: right;
  @include default;
}

.fullWidth {
  width: 100%;
}

.outlined {
  background-color: transparent;
  border: 1px solid $primary;
  &:hover {
    background-color: transparent;
  }
}

.primaryTextColour {
  color: $white;
}

.secondaryTextColour {
  color: $primary;
}

.tertiaryTextColour {
  color: $gray-140;
}

.underline {
  text-decoration: underline;
}
