@import '@percent/admin-dashboard/identity.scss';

.percentLogo {
  display: flex;
  padding-left: 25px;
  div {
    margin-left: 8px;
  }
}

.dividerListItem {
  padding: 0px 25px 11px;
}

.divider {
  padding: 0 95px;
  margin-top: 8px;
}

.pbpLogo {
  display: flex;
  justify-content: center !important;
  border-top: 1px solid $gray-040;
  padding: 20px 0;
}

.drawer {
  div:nth-of-type(1) {
    justify-content: space-between;
  }
}

.itemWithLogo {
  height: 36px;
  padding: 10px 16px;
}

.menuList {
  span {
    font-family: 'MarkProBook', sans-serif;
  }
}

.sidebarCategory {
  display: flex;
  align-items: center;
  padding-left: 24px;
  margin-top: 21px;
  height: 32px;
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  color: $gray-100;
}
