@import '@percent/admin-dashboard/identity.scss';

.nonProfitRow {
  max-width: 250px;
  width: 250px;
  cursor: default;
}

.nameRow {
  max-width: 150px;
  width: 150px;
}

.organisationIdRow {
  max-width: 200px;
  width: 200px;
}

.emailRow {
  max-width: 200px;
  width: 200px;
}

.modalBody {
  margin: 0 24px 34px;
}

.dialogFooter {
  margin: 0 24px 24px;
  text-align: right;
  button {
    width: 145px;
    height: 48px;
    &:first-child {
      margin-right: 8px;
    }
  }
}

.checkMark {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($success, 0.18);
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: 4px solid rgba($success, 0.1);
  background-clip: padding-box;
  margin-bottom: 16px;
  svg {
    height: 40px;
    width: 40px;
  }
}

.newClaim {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14em;
  width: 100%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($alert, 0.18);
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: 4px solid rgba($alert, 0.1);
  background-clip: padding-box;
  margin-bottom: 16px;
  svg {
    height: 40px;
    width: 40px;
    path {
      fill: $alert;
    }
  }
}

.caption {
  font-family: 'MarkPro', sans-serif;
  font-size: 16px;
  color: $gray-140;
  margin: 8px 0 64px;
}

.dialogBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 24px;
}

.indicator {
  background-color: $gray-020;
  margin-top: -1px;
  width: 100%;
  height: 1px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}

.centerButton {
  width: 145px;
  height: 48px;
}

.rowIconForward {
  width: 20px;
}

.arrow {
  transform: rotate(-90deg);

  path {
    fill: $secondary;
  }
}

.unrejectButton {
  position: relative;
}