@import '@percent/admin-dashboard/identity.scss';

.modalBody {
  margin: 0 24px 28px;
}

.dialogBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.checkMark {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($success, 0.18);
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: 4px solid rgba($success, 0.1);
  background-clip: padding-box;
  margin-bottom: 16px;
  svg {
    height: 40px;
    width: 40px;
  }
}

.centerButton {
  width: 145px;
  height: 48px;
}

.modalForm {
  margin: 0 24px 32px;
}

.currentStatus {
  display: flex;
  justify-content: space-between;
  background: $gray-010;
  border-radius: 4px;
  padding: 16px;
  font-family: 'MarkProMedium', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: $gray-160;
}

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  margin: 0 24px 24px;
  button {
    width: 145px;
    height: 48px;
    &:first-child {
      margin-right: 8px;
    }
  }
}
