@import '@percent/admin-dashboard/identity';

.row {
  cursor: pointer;

  &:hover {
    background-color: $gray-010 !important;
  }
}

.rowIconForward {
  width: 16px;
}

.arrow {
  transform: rotate(-90deg);

  path {
    fill: $secondary;
  }
}

@mixin badge-base {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  width: max-content;

  img {
    margin-right: 4px;
  }
}

.approved {
  @include badge-base;
  background-color: $success-100;
}

.rejected {
  @include badge-base;
  background-color: $alert-100;
}

.deleted {
  @include badge-base;
  background-color: $alert-100;
}

.pending {
  @include badge-base;
  background-color: $gray-020;
}
