@mixin text {
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.textWrapper {
  font-size: 14px;
  font-weight: 500;
}
