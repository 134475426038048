@import '@percent/admin-dashboard/identity.scss';

.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 56px;
}

.container {
  display: flex;
  width: 368px;
  flex-direction: column;
  align-items: center;
}

.header {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 16px;
  text-align: center;
  color: $secondary !important;
}

.subtext {
  font-size: 12px;
  color: $gray-160 !important;
  margin-bottom: 18px;
  text-align: center;
}

.doneButton {
  margin-top: 26px;
  padding: 25px;
}

.icon {
  margin-bottom: 24px;
}
