@import '@percent/admin-dashboard/identity.scss';

.dialogBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.caption {
  font-family: MarkPro, Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: $gray-140;
  text-align: center;
  margin: 8px 109px 64px;
}

.checkMark {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($success, 0.18);
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: 4px solid rgba($success, 0.1);
  background-clip: padding-box;
  margin-bottom: 16px;
  svg {
    height: 40px;
    width: 40px;
  }
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($alert, 0.18);
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: 4px solid rgba($alert, 0.1);
  background-clip: padding-box;
  margin-bottom: 16px;
  svg {
    height: 40px;
    width: 40px;
    path {
      fill: $alert;
    }
  }
}

.modalBody {
  margin: 0 24px 28px;
}

.form {
  margin: 0 24px 32px;
}

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  margin: 0 24px 24px;
}

.organisationName {
  font-family: 'MarkProMedium', Arial, Helvetica, sans-serif;
  font-weight: 450;
}
