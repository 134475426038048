@import '@percent/admin-dashboard/identity.scss';

.ListItemButton {
  border: none;
  position: absolute;
  right: 0px;
  top: 8px;
  align-self: flex-start !important;
  background: transparent !important;

  span {
    flex-direction: row-reverse;
    color: $secondary !important;
    text-decoration: underline;
    font-family: 'MarkPro', sans-serif;
  }

  svg {
    width: 16px;
    height: 16px;
    padding-right: 8px;
  }
}
