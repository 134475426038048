@import '@percent/admin-dashboard/identity';

.tableWrapper {
  table thead th {
    font-family: 'MarkProMedium', sans-serif;
  }
}

.tableRow {
  border: {
    top: 1px solid $gray-020;
    bottom: 1px solid $gray-020;
  }
}
