@import '@percent/admin-dashboard/identity.scss';

.row {
  cursor: pointer;

  a {
    text-decoration: none;
    color: $secondary;

    &:visited {
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: underline;
    }
  }
}

.rowIconForward {
  width: 16px;
}

.arrow {
  transform: rotate(-90deg);

  path {
    fill: $secondary;
  }
}

.date {
  white-space: nowrap;
}

.searchContainer {
  padding: 32px 0 16px 0;
  max-width: 560px;
}
