.container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0;
}

.leftPanel,
.rightPanel {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
}
