@import '@percent/admin-dashboard/identity.scss';

.buttonsWrapper {
  margin-bottom: 16px;
  text-align: right;

  button {
    margin-left: 8px;
  }
}

.info {
  display: flex;
  flex-flow: row;
  margin-bottom: 36px;

  svg {
    margin-right: 4px;
  }
}

.label {
  margin-bottom: 5px;
  font-size: 12px;
}

.formFieldWrapper {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 12px;
  }
}

.datePickerWrapper {
  position: relative;

  input {
    position: relative;
    padding: 14px 32px 14px 14px;
    font-size: 14px;
    line-height: 19px;
    background-color: $gray-000;
    border-radius: 8px;
    border: 1px solid $gray-100;
    color: $gray-160;
    cursor: pointer;
    
    &::placeholder {
      color: $gray-160; 
      opacity: 1;
    }

    &:focus {
      border: 1px solid $gray-100;
    }
  }
  
  svg {
    position: absolute;
    top: 48%;
    right: 20px;
    transform: translateY(-50%);
  }
}

