@import '@percent/admin-dashboard/identity';

.countContainer {
  display: flex;
  justify-content: space-between;

  button:hover {
    .icon {
      path {
        fill: #FF6672;
      }
    }
  }
}

.viewDonationsButtonContainer {
  display: flex;
  justify-content: center;
}

.icon {
  path {
    fill: $gray-160;
  }

  margin-right: 10px;
}
