@import '@percent/admin-dashboard/identity.scss';

.fullWidth {
  width: 100%
}

.paper {
  border-radius: 4px;
  min-width: 240px;
  box-shadow: 0 8px 32px rgba(33, 37, 53, 0.16);
}

.listBox {
  max-height: 300px;
  overflow-y: auto;
  padding: 8px 0;
  margin: 0;

  li {
    &:hover{
      background-color: rgba(0, 0, 0, 0.04)
    }
  }

  [class^="Loader"] {
    padding: 8px 0;
  }
}

.option {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 13px;
  padding: 4px;

  &.disabled {
    opacity: 0.6; 
  }
}

.optionWithBorder::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $gray-020;
}

.selectOptionRadio {
  padding: 0 8px 0 0;
}

.select {
  border-radius: 8px;
  border: 1px solid $gray-100;

  &.filter {
    display: flex;
    overflow: visible; 
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;
    border-radius: 0.25rem;
    height: 2rem;
    background-color: $gray-010;
    border: none;
  
    &.filled {
      background-color: $primary;
    }
  }
}

.autocompleteTextField {
  padding: 0 !important;
  border: none !important;

  & > div {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;

    &::before , &::after {
      content: none;
    }
  }
}

.autocompleteInput {
  padding: 14px 32px 14px 14px !important;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;

  &.filter {
    padding: 0 1.5rem !important;
    height: 2rem;

    &.filled, &.filled.filled::placeholder {
      color: $white;
    }
  }
}

.autocompleteInput, .autocompleteInput::placeholder {
  font-size: 14px;
  font-family: "MarkPro";
  color: $gray-160;
  opacity: 1;  
}

.autocompleteInput.filter, .autocompleteInput.filter::placeholder {
  font-weight: 500;
  font-size: 13px;
  color: $primary; 
}