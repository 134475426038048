.noResultTableContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigNoResultTableContainer {
  height: 57vh;
}

.smallNoResultTableContainer {
  height: 5vh;
}

.title {
  font-size: 18px;
}

.smallTitle {
  font-size: 16px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}