@import '@percent/admin-dashboard/identity.scss';

$maxLayoutWidth: 1136px;

.modalHeaderWrapper {
  position: relative;
  height: 72px;
  padding: 0 152px;
  border-bottom: 1px solid $gray-040;

  div {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: $maxLayoutWidth;

    h3 {
      margin: 0;
      padding: 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: $secondary;
    }
  }
}

.closeButton {
  position: absolute;
  top: 31px;
  left: 50px;
  display: flex;
  cursor: pointer;
  height: 16px;
  width: 16px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;

  svg {
    height: 12px;
    width: 12px;
    path {
      fill: $gray-120;
    }
  }
}

.modalContentContainer {
  margin: auto;
  max-width: $maxLayoutWidth;
  padding: 0 152px 20px;
  margin-top: 32px;
  min-height: 100vh;
}

.generalInfoWrapper {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 16px 24px !important;
  border: 1px solid #f1f0f2;

  h5 {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: $secondary;
    margin-right: 8px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: $gray-160;
  }

  hr {
    margin: 0 16px;
    border-color: $gray-040;
  }
}

.modalContentWrapper {
  display: flex;
  width: 100%;
  gap: 120px;
  margin-top: 32px;
}

.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.detailsBox {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-width: 368px;
  max-width: 368px;
  border-radius: 8px;
  border: 1px solid $gray-040;
  height: fit-content;
  font-size: 16px;

  h6 {
    margin: 0;
    font-size: unset;
    font-weight: 500;
  }

  > :last-child {
    margin-top: 20px;
    color: $gray-160;
  }
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #f1f0f2;

  span {
    display: flex;
    align-items: center;
    gap: 4px;

    a {
      color: #494550;
      text-decoration: none;
      border-bottom: 1px solid #494550;
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: $secondary;
      }
    }
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 20px;

  p {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
