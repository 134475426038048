@import '@percent/admin-dashboard/identity.scss';

.errorContainer {
  min-height: 10vh;
  margin-top: 5px;
}

.errorMessage {
  color: $gray-140;
  margin: 20px;
}
