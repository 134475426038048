@import '@percent/admin-dashboard/identity';

.addRegistryButton {
  position: absolute;
  right: 0;
}

.countryName {
  display: flex;
  align-items: center;
  span {
    margin-left: 16px;
  }
}
