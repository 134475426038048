@import '@percent/admin-dashboard/identity.scss';

.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 56px;
}

.container {
  display: flex;
  width: 368px;
  flex-direction: column;
  align-items: center;
}

.header {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 16px;
  color: $secondary !important;
}

.link {
  font-size: 14px;
  font-weight: 400;
  color: $alert !important;
  text-decoration: underline;

  &:hover {
    color: $alert !important;
  }

  &:active {
    color: $alert !important;
  }
}

.text {
  font-size: 14px;
  color: $gray-160 !important;
  margin-top: 16px;
  text-align: center;
}

.subtext {
  font-size: 12px;
  color: $gray-160 !important;
  margin-bottom: 18px;
}

.copyWrapper {
  display: flex;
  margin-bottom: 40px;
}

.button {
  background-color: $gray-020 !important;
  color: $gray-140 !important;
  font-weight: 700;
  margin-right: 8px;
}

.nextButton {
  padding: 25px;
}

.qrCode {
  margin: 40px 0;
}
