@import '@percent/admin-dashboard/identity.scss';

.tableContainer {
  box-shadow: none;
  border-radius: 8px;
  padding: 12px 24px 0;
}

.tabs {
  display: flex;
  height: 64px;

  button {
    min-width: auto;
    padding: 0 0;
    margin: 6px 32px 6px 0;
  }

  &.MuiTabs-scroller {
    border: 1px solid;
  }
}

.tabs.MuiTabs-scroller {
  border: 1px solid;
}

.indicator {
  background-color: $gray-020;
  margin-top: -1px;
  width: 100%;
  height: 1px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}

.table {
  border: none;
  td,
  th {
    border: none;
    padding: 16px 16px 16px 0;
    color: var(--colors-black);
    border-bottom: 1px solid var(--colors-gray-80);
  }

  th {
    font-weight: 500;
  }

  .footerCell {
    border-bottom: none;
    padding: 0 0 12px;
  }
}

.toolbar {
  margin-bottom: 16px;
  padding-left: 0;
  padding-right: 0px;
}

.filtersContent {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
