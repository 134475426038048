@import '@percent/admin-dashboard/identity.scss';

.container {
  width: 100%;
  height: 368px;
}

.doneButton {
  height: 50px;
  border-radius: 8px;
  min-width: 141px;
}

.doneButtonContainer {
  padding-top: 80px;
}

.innerGreenCircle,
.innerGreenCircle {
  height: 72px;
  width: 72px;
}

.tick,
.cross {
  padding-top: 26px;
  height: 22px;
  width: 26px;
  position: absolute;
}

.successContainer {
  padding-top: 50px;
  position: relative;
}

.outerGreenCircle,
.outerRedCircle {
  height: 80px;
  width: 80px;
  position: absolute;
  margin-top: -4px;
}

.titleContainer {
  padding-top: 30px;
}
