@import '@percent/admin-dashboard/identity.scss';

.loader,
.authLoader {
  padding: 40px;

  div {
    display: block;
  }
}

.loader {
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.authLoader {
  display: inline-block;
}
