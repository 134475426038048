@import '@percent/admin-dashboard/identity.scss';

@mixin text {
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.subtext {
  @include text;

  margin-top: 5px;
  font-size: 14px;
  color: $gray-160;
  display: flex;
  align-items: center;
  gap: 8px;
  a {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8px;

    svg {
      border-radius: 4px !important;
      margin-right: 4px;
    }
  }

  .websiteText {
    font-weight: bold;
  }
}
