.menu {
    padding: 6px 4px;
    min-width: auto;
    display: flex;
    justify-content: flex-end;
    width: 90px;

    span {
        width: auto;
    }
}