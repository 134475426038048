.checkActionsWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
}

.jiraButtonWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
