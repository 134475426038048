.radioCheckboxGroupContainer {
  display: flex;

  .errorContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    & > span {
      margin: unset;
      line-height: unset;
    }
  }

  label {
    margin-right: 8px;

    & > span {
      margin: unset;
      line-height: unset;
    }
  }

  input:read-only {
    background: unset;
    border: none;
  }
}
