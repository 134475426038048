@import '@percent/admin-dashboard/identity.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tagsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0px;
  padding-left: 0;

  li {
    display: flex;
    flex-direction: column;
    min-height: 48px;
  }
}

.tagTitle {
  display: flex;
  flex-direction: row;
  padding-top: 12px;

  p {
    margin: 0;
    font-family: 'MarkProMedium', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: $gray-190;
  }

  svg {
    height: 19px;
  }
}

.subTagsContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 0 12px 0;
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
}
