@import '@percent/admin-dashboard/identity.scss';

.close {
  position: absolute;
  top: 31px;
  left: 50px;
  display: flex;
  cursor: pointer;
  height: 12px !important;
  width: 12px;
  padding: 0;
  background: transparent;
  border: none;

  svg {
    height: 12px;
    width: 12px;
    path {
      fill: $gray-120;
    }
  }
}

.container {
  width: 100vw;
  height: 100vh;
  background-color: $white !important;
}

.passwordContainer {
  text-align: center;
  width: 464px;
  margin: 72px auto 0;
}

.setupButton {
  padding: 24px;
  margin-top: 10px;
}

.headerOne {
  margin-top: 56px;
  color: $secondary;
}

.headerTwo {
  margin-top: 16px;
  color: $gray-160;
}

.passwordForm {
  margin-top: 28px;
}

.lockIcon {
  margin-bottom: 40px;
}
