@import '@percent/admin-dashboard/identity.scss';

.label {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--colors-black);
}

.select {
  height: 48px;

  .buttons {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    pointer-events: none;

    .clear {
      pointer-events: auto;
      cursor: pointer;
    }
  }
}

.container {
  fieldset {
    border: 1px solid $gray-040 !important;
    border-radius: 4px;
  }

  &:hover {
    fieldset {
      border: 1px solid $gray-040 !important;
    }
  }
}

.placeholder {
  position: absolute;
  left: 14px;
  bottom: 16px;
  color: $gray-160;
  font-size: 12px;
  line-height: 16px;
}

.emptyOption {
  height: 0;
  padding: 0;
  margin: 0;
}
