@import '@percent/admin-dashboard/identity.scss';

.row {
  cursor: pointer;
}

.rowNoWrap {
  white-space: nowrap;
}

.rowIconForward {
  width: 16px;
}

.arrow {
  transform: rotate(-90deg);

  path {
    fill: $secondary;
  }
}
