.tableContainer {
  padding-top: 20px;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 0;
}

.contentTitle {
  font-size: 20px;
  font-weight: 500;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid #eceff3;
  font-size: 14px;
  font-weight: 500;
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
}

.acceptedDomainsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.acceptedDomains {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  button {
    padding: 0;
    margin-top: 0.375rem;
    text-decoration: underline;
  }
}

.addDomain {
  button {
    padding: 0;
    text-decoration: underline;
    color: #0e0f16;
    height: auto;
  }
}
