.container {
  padding: 24px 24px 12px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  h6 {
    font-weight: bold;
  }
}

.buttonsWrapper {
  display: flex;
  gap: 4px;
}

.messageWrapper {
  width: 100%;
  margin: 16px 0;

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  > div {
    width: 100%;
    margin-top: 16px;
  }
}
