.currencyInputWrapper {
  border: 0.0625rem solid var(--colors-gray-80);
  position: relative;
  box-sizing: border-box;
  height: 2.5rem;
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: white;
  width: 100%;
  font-size: 14px;
  &::placeholder {
    font-size: 14px;
    color: var(--colors-gray-700);
  }

  &:hover {
    border: 0.0625rem solid var(--colors-gray-70);
    outline: 0.25rem solid var(--colors-gray-80);
  }
  &:focus-visible {
    outline: 1px var(--colors-gray-70);
  }
  &:focus,
  &:active {
    border: 1px solid var(--colors-black);
  }
}

.danger {
  border: 0.0625rem solid var(--colors-alert-400);

  &:hover {
    outline: 0.25rem solid var(--colors-alert-100);
  }

  &:focus,
  &:active {
    border: 0.0625rem solid var(--colors-alert-400) !important;
  }
}
