@import '@percent/admin-dashboard/identity.scss';

@mixin buttonWrapperBase {
  display: flex;
  margin-top: 20px;
  margin-bottom: 24px;

  button {
    width: 145px;
    height: 48px;
  }
}

.singleButtonWrapper {
  @include buttonWrapperBase;

  margin-top: 32px;
  justify-content: center;
}

.icon {
  padding-top: 30px;
  display: block;
  margin: auto;
}
