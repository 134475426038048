@import '@percent/admin-dashboard/identity.scss';

.tableContainer {
  box-shadow: none;
  padding: 20px 30px 0px 0px;
}

.moduleDescriptionCell {
  width: 400px;
  padding: 4px 16px 0 0 !important;
  img {
    text-align: center;
    vertical-align: middle;
    padding-right: 20px;
  }
}

.logoTableCell > p {
  font-family: 'MarkProBook', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: $gray-160;
}

.statusTextTableCell {
  width: 50px;
}

.statusSwitchTableCell {
  width: 150px;
  text-align: right;
  padding-right: 0 !important;
  div {
    display: inline-block;

    div {
      display: none;
    }
  }
}

.table {
  border-top: 0px;
  margin-bottom: 48px;

  td,
  th {
    padding: 0 16px 0 0;
  }
}

.moduleTitle {
  display: inline-block;
}

.infoText {
  font-size: 16px;
  span {
    font-size: 12px;
    color: $gray-140;
  }
}

.deleteButton {
  height: 40px;
  width: 140px;
}

.footer {
  td {
    padding: 32px 16px 32px 0;
  }
  margin-bottom: 32px;
}

.filterGreen {
  filter: invert(0%) sepia(79%) saturate(126%) hue-rotate(86deg) brightness(108%) contrast(119%);
}

.filterBlue {
  filter: invert(0%) sepia(50%) saturate(150%) hue-rotate(170deg) brightness(100%) contrast(119%);
}
