.headerList {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  gap: 10px;

  li {
    display: flex;
    align-items: center;
  }
}
