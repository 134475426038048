.container {
  display: flex;
  border: 1px solid #f1f0f2;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 16px;

  > div:not(:last-child) {
    border-right: 1px solid #f1f0f2;
  }
}

.detailWrapper {
  display: flex;
  flex-direction: column;
  padding: 8px;
  flex: 1;

  > span {
    font-size: 14px;
    line-height: 24px;
  }
}

.found {
  background-color: #f7fbfa;
}

.notFound {
  background-color: #fefbf8;
}
