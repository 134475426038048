@import '@percent/admin-dashboard/identity.scss';

@mixin text {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.listContainer {
  margin-top: 24px;
  border-radius: 8px;
  padding: 28px 24px 34px !important;
}

.title,
.missionTitle {
  border-bottom: 1px solid $gray-040;
  padding-bottom: 28px;

  h6:first-of-type {
    font-weight: bold;
  }
}
.title {
  flex-direction: column;
}

.title,
.headingWrapper {
  display: flex;
  justify-content: space-between;
}

.detailsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0px;
  padding-left: 0;

  li {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gray-040;
    min-height: 48px;
    position: relative;

    p {
      margin: 16px 0 16px;
      min-width: 114px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $secondary;
    }

    span,
    a {
      @include text;

      margin: 16px 0 16px;
      color: $gray-160;
    }

    button {
      align-self: center;

      span {
        margin: 0;
        color: $primary;
        line-height: 17px;
      }
    }
    button:disabled {
      background-color: $gray-020;
      span {
        color: $gray-140 !important;
      }
    }

    td {
      padding: 0px !important;
      border-bottom: none !important;
      display: flex !important;
      flex: 1;
      justify-content: flex-end;
      align-items: center;

      li {
        padding: 0px !important;
        border-bottom: none !important;
        width: 15px;

        h6 {
          margin: 5px 0;
        }
      }
    }
  }
}

.text {
  @include text;

  margin-right: 16px;
}

.subtext {
  @include text;
  overflow: visible;

  color: $gray-160;
}

.generalInfoWrapper {
  display: flex;
  align-items: center;
  margin-top: 88px;
  border-radius: 8px;
  padding: 16px 24px !important;
}

.divider {
  margin: 0 16px;
}

.status {
  align-self: center;
  justify-content: center;
  width: 64px;
  height: 28px;
}

.titleSubText {
  margin-top: 8px;
  color: $gray-160;
}

.searchActivityCheck {
  margin-top: 20px;
  flex-direction: column !important;
  border-bottom: none !important;

  h6 {
    margin-bottom: 5px;
  }
}

.activityCheckCodeText {
  display: flex;
  color: $gray-160;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  span {
    font-weight: bold !important;
    margin-right: 5px !important;
  }
}

.button {
  padding: 0 12px;
  min-width: auto;

  span {
    line-height: 17px !important;
  }
}

.menuItem {
  background: #ffffff;
  border-radius: 4px;
}

.ellipsisIcon {
  cursor: pointer;
}
