@import '@percent/admin-dashboard/identity.scss';

.actionsButton {
  height: 40px;
  margin-top: 16px;
}

.historyContainer {
  padding: 24px 24px 0 24px;
  flex: 1;
}

.detailsHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-040;
  padding-bottom: 15px;

  h6 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: $secondary;
  }
}

.historyTable {
  padding: 10px 0 0 0;

  tr {
    td:first-child:not(:has(h6)) {
      text-transform: capitalize;
    }
  }
}
