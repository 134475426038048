@import '@percent/admin-dashboard/identity.scss';

.container {
  position: absolute;
  width: 100%;
  z-index: 2147483647;
}

.bar {
  background-color: $warning-light;
  text-align: center;
  border-radius: 0px 0px 8px 8px;
  height: 6px;
}

.message {
  font-size: 14px;
  color: $warning;
  width: 260px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  transform: translateX(-120px);
  background-color: $warning-light;
  border-radius: 0px 0px 8px 8px;
  top: 0;
  left: 50%;
  height: 30px;
  line-height: 30px;
  margin: 0;
}
