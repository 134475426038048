@import '@percent/admin-dashboard/identity.scss';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 12px;
  }

  &::after {
    content: '';
    height: 32px;
    width: 1px;
    margin: 0 12px 0 0;
    background-color: $gray-040;
  }
}

.selectLabel {
  z-index: 1;
  position: absolute;
  pointer-events: none;
  color: $primary;
  font-weight: 500;
  font-size: 13px;
  white-space: nowrap;
  font-family: "MarkPro";
  transition: none;
  top: 50%;
  left: 1.5rem;
  transform: translate(0, -50%);

  &[data-shrink="true"] {
    display: none;
  }
  
}

.menuItem {
  padding-right: 20px;
}

.menuItemWithSeparator {
  border-bottom: 1px solid $gray-020;
}

.menuItemRadio {
  padding: 4px 8px 4px 0;
}

.select {
  margin-right: 12px;
  padding: 0 !important;
  border-radius: 0.25rem;
  height: 2rem;
  background-color: $gray-010 !important;
  border: none;
  color: $primary;
  font-family: "MarkPro";
  font-weight: 500;

  &.foundation {
    & > div {
      min-width: 85px;
    }
  }

  &:hover, &:focus, &:active {
    background-color: $gray-020 !important;
  }

  & > div {
    padding: 10px 3rem 10px 1.5rem !important;
    min-width: 55px;
    background-color: transparent !important;
    font-size: 13px;
  }

  & > svg {
    position: absolute;
    right: 1.5rem;
    min-width: 24px;
    width: 24px;
    height: 24px;
    pointer-events: none;
  }

  &::before, &::after {
    content: none;
  }
}

.statusFilterItemWrapper {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 330px;
  padding: 6px 0;
}

.statusFilterItemTextWrapper {
  margin-top: 4px;

  & > * {
   white-space: normal;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    color: $gray-140;

    &:first-of-type {
      color: $gray-190;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }
}