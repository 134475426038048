@import '@percent/admin-dashboard/identity.scss';

.wrapper {
  padding: 24px;
}

.title {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid $gray-020;
  padding: 30px;
  align-items: center;

  h6 {
    margin-right: 16px;
  }
}

.titleBadge {
  margin-left: 25px;
}

.titleButton {
  margin-left: auto;
}

.detailsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 22px;
  padding-left: 0;

  li {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gray-020;
    min-height: 48px;
    position: relative;
    align-items: center;

    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 16px 0 16px;
      min-width: 224px;
      font-weight: normal !important;

      &:first-of-type {
        color: $secondary;
      }
    }

    button {
      align-self: center;
    }
  }
}

.donationAmount {
  p:first-of-type {
    margin-left: 24px;
    min-width: 200px;
  }
}

.tableTitle {
  width: 100%;
  margin-top: 60px;
  padding-bottom: 8px;
  border-bottom: 1px solid $gray-020;
}

.error {
  padding: 12px 0;
  color: $alert
}

.loaderBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableRow {
  border: {
    top: 1px solid $gray-020;
    bottom: 1px solid $gray-020;
  }
}
