@import '@percent/admin-dashboard/identity.scss';

@mixin buttonWrapperBase {
  display: flex;
  margin-top: 20px;
  margin-bottom: 24px;

  button {
    width: 145px;
    height: 48px;
  }
}

.form {
  label {
    margin-top: 20px;
  }
}

.container {
  margin-top: 24px;
  border-radius: 8px;
  padding: 28px 24px 24px !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray-040;
  padding-bottom: 19px;
}

.subTitle {
  margin: 24px 0 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: $gray-160;
}

.buttonUpload {
  background-color: $gray-010;
}

.previewButton {
  border: none;
  margin-top: -6px;
  padding-inline: 16px;

  span {
    color: $primary;
    text-decoration: underline;
  }
}

.singleButtonWrapper {
  @include buttonWrapperBase;

  margin-top: 32px;
  justify-content: center;
}

.icon {
  padding-top: 30px;
  display: block;
  margin: auto;
}
