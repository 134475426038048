@import '@percent/admin-dashboard/identity.scss';

@mixin icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($success, 0.18);
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: 4px solid rgba($success, 0.1);
  background-clip: padding-box;
  margin-bottom: 16px;
  svg {
    height: 40px;
    width: 40px;
  }
}

.checkMark {
  @include icon;
}

.close {
  @include icon;

  background-color: rgba($alert, 0.18);
  border: 4px solid rgba($alert, 0.1);
  svg {
    height: 27px;
    width: 27px;

    path {
      fill: $alert;
    }
  }
}

.dialogBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 24px;

  h5 {
    margin-bottom: 8px;
  }
}

.centerButton {
  width: 145px;
  height: 48px;
  margin-top: 40px;
}

.modalBody {
  margin: 0 24px 40px;

  p {
    font-family: 'MarkPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: $secondary;
  }
}

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  margin: 0 24px 24px;
  button {
    width: 185px;
    height: 48px;
    &:first-child {
      margin-right: 8px;
    }
  }
}
