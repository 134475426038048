@import '@percent/admin-dashboard/identity.scss';

@mixin text {
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.divider {
  height: 1px;
  width: calc(100% - 40px);
  margin-bottom: 16px;
}

.fraudAlertWrapper {
  width: 100%;
  margin-bottom: 10px;
  padding-right: 40px;
}

.text {
  @include text;

  font-size: 16px;
}

.subtext {
  @include text;

  margin-top: 5px;
  font-size: 14px;
  color: $gray-160;
}

.organisationInfoWrapper {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  display: flex;
  align-items: center;

  button:nth-child(2) {
    margin-left: 16px;
  }

  button {
    width: 152px;
    height: 40px;
  }

  .rejectButton {
    background-color: $alert-light;
    color: $alert;

    &:hover {
      background-color: $alert;
      color: $white;
    }
  }

  .approveButton {
    background-color: $successLight;
    color: $success;
    margin-left: 16px;

    &:hover {
      background-color: $success;
      color: $white;
    }
  }

  .triggerEmailVerificationButton {
    background-color: $gray-010;
    color: $gray-170;
    width: auto;

    &:hover {
      background-color: $gray-020;
    }
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.globalFlagsList {
  margin: 0;
  padding-left: 16px;
}

.decisionContainer {
  padding-bottom: 0px !important;
}
