.tableCell {
  padding-right: 0 !important;
  div {
    display: inline-block;
  }
}

.tableContainer {
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 0px;
}

.table {
  td,
  th {
    padding: 16px 16px 16px 0;
  }
}

.addButton {
  position: absolute;
  right: 0px;
}

.toolbar {
  padding: 0;
}