@import '@percent/admin-dashboard/identity';

@mixin text {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0;
}

.leftPanel,
.rightPanel {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
}

.listContainer {
  margin-top: 24px;
  border-radius: 8px;
  padding: 28px 24px 34px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  font-weight: bold;
}

.editButton {
  position: relative;
  top: 5px;
}

.bankDetailsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0px;
  padding-left: 0;
  font-weight: normal;
  border-top: 1px solid $gray-040;

  li {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gray-040;
    min-height: 48px;
    position: relative;

    p {
      display: flex;
      align-items: center;
      margin: 16px 0 16px;
      min-width: 200px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $secondary;
    }

    span {
      @include text;
      display: flex;
      align-items: center;
      margin: 16px 0 16px;
      color: $gray-160;
      min-width: 200px;
    }

    div {
      @include text;
      display: flex;
      align-items: center;
    }

    img {
      margin-right: 7px;
    }
  }
}

.link {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: $secondary;
  cursor: pointer;
  text-decoration: underline;
}

.galleryContainer {
  margin-top: 8px;
} 

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.validationStatus {
  display: flex;
  align-items: center;
  gap: 4px;
}

.errorBox {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 24px;
}

.alertContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
