@import '@percent/admin-dashboard/identity.scss';

@mixin text {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.generalInfoWrapper {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 16px 24px !important;
  margin-top: 100px;
}

.flagWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  padding: 16px 24px !important;
  margin-top: 24px !important;
}

.divider {
  margin: 0 16px;
}

.algoliaContainer {
  margin-top: 24px;
  border-radius: 8px;
  padding: 24px 24px 34px !important;
}

.headerText {
  font-family: 'MarkProBook', sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: $secondary;
  margin-bottom: 8px;
}

.checkboxLabel {
  font-family: 'MarkProMedium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: $secondary;
}
