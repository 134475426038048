.wrapper {
  margin-bottom: 16px;
  padding: 12px 18px;
  background-color: var(--colors-alert-30);
  border: 1px solid var(--colors-alert-100);
  border-left: 2px solid var(--colors-alert-400);
  border-radius: 0;

  & > div {
    margin: 0;
    padding: 0;

    &:first-of-type {
      margin-right: 12px;
    }
  }
}

.text > * {
  line-height: 24px !important;
}

.header {
  color: var(--colors-black);
}

.list {
  margin: 0;
  padding: 0 0 0 22px;
}

.list, .footer {
  color: var(--colors-neutral-500);
}
