@import '@percent/admin-dashboard/identity.scss';

.vettingEventCheckWrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #f1f0f2;
  gap: 16px;
  transition: 0.5s ease-in;

  h5 {
    margin: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}

.checkWrapperCustomBackground {
  background: #fefbf8;

  input {
    background: #ffffff;
  }
}

.radioWrapper {
  display: flex;
  gap: 24px;
}
