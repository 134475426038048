@import '@percent/admin-dashboard/identity.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 464px;
  padding: 48px;
  border-radius: 8px !important;
}

.title {
  margin-bottom: 8px;
  font-size: 24px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.subtitle {
  margin-bottom: 40px;
  text-align: center;
  color: $gray-160;
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 24px;
  overflow: hidden;
  word-wrap: break-word;
}

.subtitle[strong] {
  font-family: 'MarkProBold', sans-serif;
}

.icon {
  margin-bottom: 20px;
}

.button {
  height: 48px;
}
