@import '@percent/admin-dashboard/identity.scss';

.wrapper {
  width: fit-content;
  display: flex;
  align-items: center;

  button {
    margin-left: 12px;
  }
}

.separator {
  height: 32px;
  width: 1px;
  margin-left: 12px;
  background-color: $gray-040;
}

.spacer {
  width: 12px;
}
