@import '@percent/admin-dashboard/identity.scss';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 560px;
  padding: 48px 108px;
  border-radius: 8px !important;
}

.title {
  margin-bottom: 64px;
  font-size: 24px;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.icon {
  margin-bottom: 20px;
}

.button {
  width: 145px;
  height: 48px;
}
