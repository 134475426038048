.formContainer {
  width: 100%;
  background-color: white;
  margin-top: 10vh;
  text-align: center;
  border-radius: 16px;

  svg {
    margin-top: 50px;
  }
}

.form {
  padding: 0 15px;
  padding-top: 50px;
}

.loginText {
  margin: 35px 0 20px;
}

.loginPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@media only screen and (min-width: 769px) {
  .formContainer {
    width: 560px;
    height: 600px;
  }

  .form {
    padding: 0 96px;
  }
}
