@import '@percent/admin-dashboard/identity.scss';

.row {
  cursor: pointer;
  padding-right: 0;
}

.arrow {
  transform: rotate(-90deg);

  path {
    fill: $secondary;
  }
}

.addPartnerButton {
  position: absolute;
  right: 0px;
}

.actionsAddPartnerButton {
  height: 40px;
  margin-top: 16px;
}
