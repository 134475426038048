@import '@percent/admin-dashboard/identity.scss';

.errorPage {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.errorDetails {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-height: 200px;
  overflow: scroll;
}
