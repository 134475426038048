//added to the file because of identity.scss overrides some classes in material-ui
$gray-190: #0e0f16;
$gray-010: #f6f7f9;
$gray-140: #6b7588;

@mixin text {
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

@mixin wrapperBase {
  display: flex;
  flex-direction: column;
  width: 560px;
  border-radius: 8px !important;
  background-color: white;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.wrapper {
  @include wrapperBase;
  padding: 24px;
}

.divider {
  margin-bottom: 18px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}

.title {
  @include text;

  color: $gray-190;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.text {
  @include text;

  font-size: 16px;
  font-weight: 200;
}

.textWrapper {
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 24px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.closeIcon {
  svg {
    path {
      fill: $gray-140;
    }
  }

  cursor: pointer;
  width: 11px;
  height: 11px;
}

.wrapperSuccess {
  @include wrapperBase;

  align-items: center;
  padding: 48px;
  background-color: white;
}

.successTitle {
  color: $gray-190;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
}

.icon {
  margin-bottom: 20px;
}

.button {
  margin-top: 32px;
  width: 145px;
  height: 48px;
}
