@import '@percent/admin-dashboard/identity.scss';

.loggedOutText {
  margin-bottom: 15px;
  color: $gray-160;
}

.loggedInText {
  margin: 15px 0 20px;
}

.loginButton {
  height: 48px;
  margin-top: 20px;
}

.sessionImage {
  margin-top: 30px;
  width: 218px;
  height: 228px;
}
