@import '@percent/admin-dashboard/identity.scss';

.headerWrapper {
  width: 100%;
  padding: 24px 24px 0 24px;
}

.divider {
  margin: 16px 0;
  background-color: $gray-020;
}

.menuBtnWrapper {
  padding: 16px 0;

  svg {
    cursor: pointer;
  }
}

.table {
  padding: 0 24px;

  th {
    font-family: 'MarkProMedium', sans-serif;
    padding: 12px 12px 12px 0;
  }
  thead,
  tbody {
    tr {
      border-bottom: 1px solid $gray-020;

      td {
        padding: 12px 12px 12px 0;
      }
    }
  }

  div {
    margin: 0;
  }
}

.cellGrow {
  flex-grow: 1;
}

.actionsSection {
  button {
    margin-left: auto;
    display: block
  }
}

.buttonAction {
  height: 2rem;
}

.filtersAndActionsWrapper {
  width: 100%;

  ul {
    margin: 1rem 0;
  }
}
