@import '@percent/admin-dashboard/identity';

.registriesDialogContent {
  padding: 48px 24px !important;
  p {
    margin: 16px 0 32px;
  }
}

.svg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonWrapperForm {
  text-align: right;
}

.singleButtonWrapper {
  display: flex;
  margin-top: 16px;
  justify-content: center;
}

.modalContentBox {
  box-sizing: border-box;
  overflow: hidden;
}

.modalContentWrapper {
  box-sizing: border-box;
  max-height: 92vh;
  overflow-y: auto;

  div:nth-of-type(2) {
    max-height: 100%;
  }
}
