@import '@percent/admin-dashboard/identity.scss';

.error {
  color: $primary;
  font-size: 12px;
}

.selectCurrencyCode {
  display: block;
}

.CurrencyCodeLabel {
  color: $secondary;
  font-size: 12px;
}
