@import '@percent/admin-dashboard/identity.scss';

.container {
  margin-top: 24px;
  border-radius: 8px;
  padding: 28px 24px 24px !important;
}

.title {
  padding-bottom: 19px;
}

.alertContainer {
  margin-bottom: 24px;
}

.sectionsContainer {
  background-color: $gray-010;
  padding: 16px;
  padding-bottom: 0;
}

.sectionsListContainer {
  background-color: $gray-010;
  padding: 16px;
  margin-bottom: 24px;
}

.sectionContainer {
  background-color: $gray-000;
  padding: 14px 16px 14px 16px;

  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: $gray-190;
    word-wrap: break-word;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.sectionsInputContainer {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
