@import '@percent/admin-dashboard/identity.scss';

.passwordText {
  margin-bottom: 25px;
}

p {
  color: $gray-140;
  .clickHere {
    text-decoration: underline;
    text-underline-position: under;
  }
}

.emailButton {
  border-radius: 8px;
  height: 48px;
  margin-top: 20px;
}

.footerText {
  margin-top: 30px;
}
