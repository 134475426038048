@import '@percent/admin-dashboard/identity.scss';

.row {
  cursor: pointer;

  &:hover {
    background-color: $gray-010 !important;
  }
}

.link {
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  color: $secondary;
  text-decoration: underline;

  &:visited,
  :active {
    color: $secondary;
  }
}

.rowIconForward {
  width: 16px;
}

.arrow {
  transform: rotate(-90deg);

  path {
    fill: $secondary;
  }
}

.tableCellEmail {
  max-width: 320px;
}

.agentVerificationStatus {
  min-width: 130px;
}

.dateRangeContainer {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.openDateSelector {
  background-color: $primary;
  color: white;
  width: auto;
  border-radius: 8px;

  &:hover {
    background-color: $primary;
    color: white;
  }
}

.statusCell {
  display: flex;
  flex-direction: row;
  align-items: center;

  span:first-child {
    margin-right: 24px;
  }
}

.nameRow {
  white-space: nowrap;
}
