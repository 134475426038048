@import '@percent/admin-dashboard/identity.scss';

.container {
  display: flex;
  align-items: center;

  :not(:first-child) {
    margin-top: 8px;
  }
}

@mixin checkbox {
  padding: 0 8px 0 0;
  &:hover {
    background-color: transparent;
  }
  svg {
    margin-top: 0px;

    path {
      height: 24px;
      width: 24px;
    }
  }
}

.checkbox {
  @include checkbox;

  color: $gray-040;
}

.tagCheckbox {
  @include checkbox;

  color: #5a1bee;
}

.flagCheckbox {
  @include checkbox;

  color: #6e62ff;
}

.subTagCheckbox {
  @include checkbox;

  color: $secondary;
}

.supportedTypeCheckbox {
  @include checkbox;

  color: $lavenderIndigo;
}
