@import '@percent/admin-dashboard/identity';

.dateRangePopper {
  background-color: white;
  padding: 0px 20px 20px;
  box-shadow: 0px 8px 32px rgba(33, 37, 53, 0.16);
  border-radius: 4px;
}

.popperContainer {
  position: fixed;
  top: unset;
  left: unset;
  z-index: 1301;
}

.applyButton {
  height: 40px;
  border-radius: 8px;
  border: none;
  width: 139px;
}
