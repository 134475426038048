@import '@percent/admin-dashboard/identity.scss';

.saveButton {
  height: 48px;
  margin-left: 10px;

  &:disabled {
    background-color: $gray-020;
    span {
      color: $gray-120 !important;
    }
  }
}

.input {
  label {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $secondary;
  }
}
