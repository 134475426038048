@import '@percent/admin-dashboard/identity.scss';

.row {
  cursor: pointer;

  a {
    text-decoration: none;
    color: $secondary;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    text-decoration: underline;
  }
}

.date {
  white-space: nowrap;
}

.rowIconForward {
  width: 16px;
}

.arrow {
  transform: rotate(-90deg);

  path {
    fill: $secondary;
  }
}

.emoji {
  margin-right: 2px;
}
