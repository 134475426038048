@import '@percent/admin-dashboard/identity.scss';

.footer {
  margin-top: auto;
  padding: 0 10px;
  color: $gray-140;

  p {
    margin: 30px 0;
    font-size: 12px;
  }
}
