@mixin text {
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 560px;
  padding: 24px;
  border-radius: 8px !important;
}

.divider {
  margin-bottom: 18px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
}

.title {
  @include text;

  font-size: 20px;
  font-weight: normal;
}

.text {
  @include text;

  font-size: 16px;
  font-weight: 200;
}

.textWrapper {
  margin-bottom: 40px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    width: 145px;
    height: 48px;
  }

  button:nth-child(2) {
    margin-left: 8px;
  }
}
