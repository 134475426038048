@import '@percent/admin-dashboard/identity.scss';

.dialogBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.caption {
  font-family: 'MarkPro', sans-serif;
  font-size: 16px;
  color: $gray-140;
  text-align: center;
  margin: 8px 109px 64px;
}

.checkMark {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($success, 0.18);
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: 4px solid rgba($success, 0.1);
  background-clip: padding-box;
  margin-bottom: 16px;
  svg {
    height: 40px;
    width: 40px;
  }
}

.centerButton {
  width: 145px;
  height: 48px;
  margin-top: 64px;
}

.modalBody {
  margin: 0 24px 12px;

  p {
    font-family: 'MarkPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: $secondary;
  }
}

.mainContainer {
  margin: 0 24px 32px;
}

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  margin: 0 24px 24px;
  button {
    width: 185px;
    height: 48px;
    &:first-child {
      margin-right: 8px;
    }
  }
}

.tagsList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0px;
  padding-left: 0;

  li {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $gray-020;
    min-height: 48px;
  }
}

.tagTitle {
  display: flex;
  flex-direction: row;
  padding: 12px 0 4px 0;

  p {
    margin: 0;
    font-family: 'MarkProMedium', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: $secondary;
  }

  svg {
    height: 19px;
  }
}

.subTagsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 12px;

  & > div {
    margin: 8px 8px 0 0;
  }
}

.subTag {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray-010;
  border-radius: 4px;
  padding: 2px 12px;
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  color: $gray-140;
}

.submitText {
  font-family: 'MarkProBook', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: $secondary;
}

.sensitive {
  background: $alert-100;
  color: $alert-400;
}

.textSensitive {
  display: flex;
  align-items: center;
  color: $alert-400;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  svg {
    margin-right: 8px;
    width: 14px;
    height: 14px;
  }
}
