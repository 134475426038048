.listContainer {
  display: flex;

  & > span {
    &:last-child {
      margin: 0;
      margin-top: 0;
    }

    &:not(:last-child) {
      margin: 0;
      margin-top: 0;
      margin-right: 8px;
    }
  }
}
