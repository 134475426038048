@import '@percent/admin-dashboard/identity.scss';

.confirmationCodeContainer {
  text-align: center;
  width: 464px;
  margin: 0 auto;
}

.headerOne {
  margin-top: 56px;
  color: $secondary;
}

.headerTwo {
  margin-top: 16px;
  color: $gray-160;
}

.confirmationCodeForm {
  margin-top: 28px;
}

.verifyButton {
  height: 48px;
}
