@import '@percent/admin-dashboard/identity.scss';

.complianceDetailsContainer {
  padding: 24px;
  width: 400px;
  margin-right: 16px;
}

.detailsHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-040;
  padding-bottom: 15px;

  h6 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: $secondary;
  }
}

.statusesList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin-top: 0px;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray-040;
    min-height: 48px;
    max-height: 48px;

    p {
      margin: 16px 0 16px;
      min-width: 224px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $secondary;
    }
  }
}

.dividerTitle {
  margin-top: 8px;
  font-family: 'MarkPro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  color: $gray-100;
}

.complianceList {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 16px 0 0px 0;

  .complianceSubsection {
    margin-left: 16px;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray-040;
    min-height: 48px;
    max-height: 48px;

    p {
      margin: 16px 0 16px;
      min-width: 224px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $secondary;
    }
  }
}

@mixin status {
  display: flex;
  align-items: center;
  max-height: 28px;
  font-family: 'MarkProBold', sans-serif;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  padding: 2px 12px 2px 15px;
  border-radius: 4px;

  svg {
    margin-right: 8px;
  }
}

.clear {
  color: $success;

  @include status;
}

.pending {
  color: $gray-140;

  @include status;
}

.flag {
  color: #e86825;

  @include status;
}

.na {
  color: #a0adc3;
  background-color: $gray-010;

  @include status;
}
