@import '~react-image-gallery/styles/scss/image-gallery.scss';

$secondary: #1b034f;
$blue-color-light: #e5effc;
$gray-000: #ffffff;
$gray-010: #f6f7f9;
$gray-020: #eceff3;
$gray-030: #ecf2fe;
$gray-040: #dadfe8;
$gray-050: #ced4da;
$gray-060: #e5e5e5;
$gray-080: #b3bdcf;
$gray-100: #a0adc3;
$gray-120: #8691a7;
$gray-140: #6b7588;
$gray-160: #505869;
$gray-170: #404858;
$gray-180: #404858;
$gray-190: #0e0f16;
$gray-200: #8691a7;
$primary: #ff6672;
$primaryLight: #fff0f1;
$lavenderIndigo: #8b4aff;
$info-400: #106cfe;
$alert: #e9554e;
$alert-light: #fcebea;
$alert-100: #fce9ea;
$alert-400: #e4252c;
$success: #69d4a1;
$success-100: #e5f2ed;
$success-400: #008050;
$green-100: #ccf5e1;
$green-150: #42a274;
$white: #ffffff;
$blue: #6798f8;
$successLight: #ebf9f3;
$warning-light: #fff1e0;
$warning: #e86825;
$warning-alt: #ffc31f;
$warning-alt-light: #fff9e9;
$warning-100: #fdf1e6;
$warning-400: #e77309;

.MuiTab-textColorPrimary.Mui-selected {
  color: $secondary;
}

.MuiTabs-indicator {
  height: 1px;
  bottom: 0;
  transition: none;
}

.MuiDialog-paperWidthLg {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

.MuiTab-wrapper {
  font-family: MarkProBook, Arial, Helvetica, sans-serif;
}

.MuiDialog-paperWidthLg {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

.MuiPaper-rounded {
  border-radius: 8px;
}

.MuiTableCell-body {
  font-family: MarkProBook, Arial, Helvetica, sans-serif;
}

.MuiInput-input {
  font-family: MarkPro, Arial, Helvetica, sans-serif;
}

.MuiMenu-paper {
  border-radius: 4px;
  min-width: 176px;
  box-shadow: 0 8px 32px rgba(33, 37, 53, 0.16);
}

#gallery-slider {
  display: none !important;
}

#gallery-thumbnails {
  .image-gallery-thumbnail {
    border: none !important;
    margin: 0 24px 24px 0;
  }

  .image-gallery-thumbnails-container {
    display: flex;
    flex-wrap: wrap;
  }

  .image-gallery-thumbnail.active {
    border-color: $primary;
    border-radius: 8px;
  }

  .image-gallery-thumbnail:hover {
    border: 4px solid $primary;
    border-radius: 8px;
    background: rgba(33, 37, 53, 0.16);
  }
}

#gallery-image {
  max-height: calc(100vh - 120px);
}

.image-gallery-svg {
  color: $primary;
}

.image-gallery-thumbnail.active {
  border-color: $primary;
}

.image-gallery-thumbnail:hover {
  border: 4px solid $primary;
}
