@import '@percent/admin-dashboard/identity.scss';

.loginButton {
  height: 48px;
  margin-top: 20px;
}

.loginFooterText {
  margin-top: 30px !important;
  margin-bottom: 16px;
}

p span a {
  color: $gray-160 !important;
}

.passwordText {
  margin-bottom: 25px;
  color: $gray-160;
}
