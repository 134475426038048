.infoWrapper {
  padding: 16px 15px;
  background-color: var(--colors-white);
  border: 1px solid var(--colors-info-100);
  border-left: 2px solid var(--colors-info-400);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; 

  & > div {
    margin: 0;
    padding: 0;

    &:first-of-type {
      margin-right: 15px;
    }
  }
}

.infoText {
  color: var(--colors-black);
}
