.dialogTitle {
  display: inline-block;
  font-size: 20px;
  color: var(--colors-white);
  padding-left: 0px;
}

.drawer {
  box-shadow: 0 8px 32px rgba(33, 37, 53, 0.16) !important;

  ul {
    padding: 0px;
  }

  hr {
    background-color: var(--colors-white) !important;
  }

  div:nth-of-type(3) {
    border-radius: 0px !important;
    padding: 0 20px;
    background-color: var(--colors-black);
    width: 408px;
    overflow-x: hidden;
  }
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  display: inline-flex;
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: auto;
  height: auto;
  padding: 8px;
  margin-left: 16px;
}