@import '@percent/admin-dashboard/identity.scss';
@import '../../../common/components/timeLeft/TimeLeft.module.scss';

.container {
  padding: 16px 16px 0 !important;
  margin-bottom: 24px;
}

.complianceContainer {
  display: flex;
  align-items: flex-start;
}

.nonprofitDetail {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.heading {
  margin: 12px 0 10px 0;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $secondary;
}

.menu {
  padding: 10px 4px;
  min-width: auto;
  margin: 12px 0 0 16px;
  width: 24px;
  height: 24px;

  span {
    width: auto;
  }
}

.tabs {
  display: flex;
  height: 64px;

  button {
    min-width: auto;
    padding: 0 0;
    margin: 6px 32px 6px 0;
  }
}

.tabs.MuiTabs-scroller {
  border: 1px solid;
}

.indicator {
  background-color: $gray-020;
  margin-top: -1px;
  width: 100%;
  height: 1px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}

.statusBoxActive {
  @include statusContainer;
  background: $successLight;
  margin-top: 0px;

  .successColor {
    @include statusText;
    color: $success;
  }
}

.statusBoxInactive {
  @include statusContainer;
  background: $primaryLight;
  margin-top: 0px;

  .primaryColor {
    @include statusText;
    color: $primary;
  }
}

.overviewTabContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tabItem {
  display: flex;
  flex-direction: column;
  margin: 0 24px 24px 0;
}
