@import '@percent/admin-dashboard/identity.scss';

.container {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  gap: 24px;

  :last-child {
    :after {
      border: none;
    }
  }
}

.topBarItem {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  :after {
    content: '';
    position: absolute;
    border-left: 1px solid $gray-080;
    right: -12px;
    height: 20px;
  }
}

.identityCheckFailed {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.25px;
  overflow: hidden;
  word-wrap: break-word;
  color: $gray-160;
  margin-left: 5px;
}

.topBarDetail {
  font-weight: 400;
}
