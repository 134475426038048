.tableHeaderContainer {
  display: flex;
  width: 100%;
  align-items: center;

  div {
    flex: 1;
  }
}

.menu {
  margin-left: 20px;
}

.reward {
  white-space: pre;
  width: 65vw;
}
